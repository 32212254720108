<template>
  <!-- SECTION modal-modify-payment-ancillary-services -->
  <b-modal
    id="modal-modify-payment-ancillary-services"
    :title="$t('reservation.paymentAncillary')"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    no-close-on-backdrop
    size="lg"
    @hide="handleHideModal"
    @show="handleShowModal"
  >
    <template #modal-footer="{close}">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        size="md"
        variant="outline-secondary"
        class="center rounded-pill width-100"
        @click="close()"
      >
        {{ $t('reservation.close') }}
      </b-button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :disabled="!selectedItems.length"
        class="btn-gradient mt-lg-25  border-0"
        pill
        @click="handleConfirmPaymentAncillary"
      >
        <span class="align-middle">{{ $t('reservation.confirm') }}</span>
      </b-button>
    </template>

    <div class="my-75 font-weight-bold">
      Chọn gói dịch vụ thanh toán:
    </div>

    <!-- ANCHOR - Services -->
    <b-card
      class="border"
      no-body
    >
      <!-- :tbody-tr-class="getRowClass" -->
      <b-table
        id="table-ancillary-services"
        centered
        responsive
        hover
        small
        class="mb-0"
        thead-class="text-dark text-center text-nowrap"
        tbody-class="text-dark text-center text-nowrap"
        :fields="ancillaryServicesColumns"
        :items="ancillaryServicesList"
        @row-clicked="onRowClicked"
      >
        <template
          v-for="(column, index) in ancillaryServicesColumns"
          v-slot:[`head(${column.key})`]="data"
        >
          <div
            :key="index"
            class="text-dark"
          >
            <span v-if="data.column === 'checkbox'">
              <b-form-checkbox
                v-model="allPaxsSelected"
                :disabled="!isEmpty(ancillaryServicesList) && ancillaryServicesList.some(item => !item.eticket)"
                class="mr-1 pl-0"
                inline
                @change="handlePaxsSelectAll(allPaxsSelected)"
              />
            </span>

            <span
              v-else
              class="text-nowrap"
            >
              {{ $t(`reservation.${data.column}`) }}
            </span>
          </div>
        </template>

        <template #cell(checkbox)="{ item }">
          <b-form-checkbox
            class="p-0"
            name="check-box"
            inline
            :disabled="!item.eticket"
            :checked="isChecked(item)"
            @change="chooseItem(item)"
          />
        </template>

        <template #cell(full_name)="{ item }">
          <div class="text-center">
            <small class="text-warning font-weight-bold">{{ item.passenger.paxId }}</small> {{ `${item.passenger.lastName} / ${item.passenger.firstName}` }}
          </div>
        </template>

        <template #cell(itinerary)="{ item }">

          <div
            class="text-center text-truncate"
          >
            <div v-if="!isEmpty(item.segments) && item.segments.length < 2">
              {{ item.segments[0].departure.iataCode }} - {{ item.segments[0].arrival.iataCode }}
            </div>

            <div v-if="!isEmpty(item.segments) && item.segments.length > 1">
              <span
                v-for="(segment, indexSegment) in item.segments"
                :key="indexSegment"
              >
                {{ (indexSegment === 0) ? `${segment.departure.iataCode}` : '' }}
                {{ (indexSegment !== 0) && (indexSegment !== item.segments.length - 1) ? ` - ${segment.departure.iataCode}` : ` - ${segment.arrival.iataCode}` }}
              </span>
            </div>

            <b-badge
              :variant="item.eticket ? 'success' : 'warning'"
              class="ml-1 font-weight-bold"
            >
              {{ item.eticket ? 'Đã xuất vé' : 'Chưa xuất vé' }}
            </b-badge>
          </div>
        </template>

        <template #cell(services)="{ item }">
          {{ item.serviceType }}
        </template>

        <template #cell(status)="{ item }">
          <div
            class="text-center text-truncate"
          >
            {{ item.status }}
          </div>
        </template>

        <template #cell(amount)="{ item }">
          <!-- <div
            class="text-center text-truncate"
          >
            {{ item.numberOfItems }}
          </div> -->
          <span v-if="item.serviceType === 'SEAT'">{{ item.pdcSeat }}</span>

          <div v-if="item.serviceType === 'BAGGAGE'">
            {{ (resolveSsrNameByCode(item.serviceSubCode) ) }} x{{ item.numberOfItems }}
          </div>
        </template>

        <template #cell(total)="{ item }">
          <div
            class="text-center text-truncate"
          >
            {{ formatCurrency(item.totalPrice) }}
          </div>
        </template>
      </b-table>
    </b-card>

    <b-card
      v-if="!isEmpty(ancillaryServicesList) && ancillaryServicesList.some(item => !item.eticket)"
      class="border-warning text-center my-2"
      no-body
    >
      <b-alert
        variant="warning"
        class="font-weight-bolder mb-0 px-1 py-50"
        show
      >
        Chỉ có thể thanh toán dịch vụ trên những hành trình đã xuất vé. Vui lòng xuất vé trước khi thực hiện!
      </b-alert>
    </b-card>

    <!-- ANCHOR - Payment -->
    <b-card body-class="p-1">
      <div class="mr-2 text-right">
        <span class="mr-2 font-weight-bolder"> Tổng tiền thanh toán: </span>
        <span class="font-weight-bolder text-warning font-medium-5">{{ formatCurrency(totalPriceSelected) }}</span>
      </div>
    </b-card>

  </b-modal>
</template>

<script>
import {
  BModal, BFormCheckbox, BButton, BTable, BCard, BBadge, BAlert,
} from 'bootstrap-vue'
import {
  ref, computed, watch, toRefs,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'

import { resolveSsrNameByCode, resolveTitle } from '@/constants/selectOptions'
import { formatCurrency, convertISODateTime, convertShortTrip } from '@/@core/utils/filter'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BModal,
    BFormCheckbox,
    BButton,
    BTable,
    BCard,
    BBadge,
    BAlert,
  },
  props: {
    bookingData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const {
      ancillaryPayments,
    } = useReservationHandle()

    const getBookingData = toRefs(props).bookingData
    const ancillaryServicesList = ref([])

    const selectedItems = ref(cloneDeep(ancillaryServicesList.value))
    const allPaxsSelected = ref(false)

    const totalPriceSelected = computed(() => {
      let total = 0
      if (!isEmpty(selectedItems.value)) {
        total = selectedItems.value.reduce((total, item) => total + item.totalPrice, 0)
      }
      return total
    })

    function getArrEticketBySegment(passenger, item) {
      let result

      if (!isEmpty(passenger)) {
        // lấy ra list eticket TKT, status: [OK, CKIN, USED] của tất cả paxs
        const listTicketTKT = passenger.eticket.filter(e => e.ticketType === 'TKT' && ['OK', 'CKIN', 'USED'].includes(e.status))

        if (!isEmpty(listTicketTKT)) {
          // check segment đầu tiên của từng ssr trong ancillaryServices
          const bookingSegmentByAddonsId = getBookingData.value.itineraries.flat().find(seg => seg.segmentId === item.segmentIds[0])

          // đoạn này check cái segment trong booking kiếm được từ segmentIds[0] so sánh với eticket.segments[] tìm ra eticket TKT
          result = (bookingSegmentByAddonsId && listTicketTKT
            .find(e => e.segments
              .some(item => item.departure === bookingSegmentByAddonsId.departure.iataCode && new Date(item.departureDate).toISOString() === bookingSegmentByAddonsId.departure.at))) || null
        }
      }
      return result
    }

    // Trả về list all ancillaryServices và nếu hành trình của gói ssr đã thanh toán thì thêm key eticket
    function getListServices(bookingData) {
      let result = []
      if (bookingData.ancillaryServices) {
        const listServiceNotPay = bookingData.ancillaryServices.filter(s => s.status === 'HD')
        result = listServiceNotPay.map(item => {
          const passenger = bookingData.paxLists.find(p => p.paxId === item.paxId)
          return {
            ...item,
            segments: item.serviceType === 'BAGGAGE' ? bookingData.itineraries.flat().filter(s => (item.segmentIds.includes(s.segmentId))) : [bookingData.itineraries.flat().find(p => p.segmentId === item.segmentIds[0])],
            passenger,
            eticket: (passenger && getArrEticketBySegment(passenger, item)) || null,
          }
        })
      }
      return result
    }

    function handleShowModal() {
      ancillaryServicesList.value = getListServices(getBookingData.value)
      selectedItems.value = ancillaryServicesList.value.filter(item => item.eticket)
    }

    async function handleConfirmPaymentAncillary(bvModalEvent) {
      bvModalEvent.preventDefault()

      const ssrData = selectedItems.value.map(item => {
        const itineraries = []
        const payer = item.passenger

        item.segments.forEach(segment => {
          itineraries.push({
            airline: segment.airline,
            departure: segment.departure.iataCode,
            arrival: segment.arrival.iataCode,
            departureDate: convertISODateTime(
              segment.departure.at,
              segment.departure.timeZone,
            ).ISOdatetime,
            arrivalDate: convertISODateTime(
              segment.arrival.at,
              segment.arrival.timeZone,
            ).ISOdatetime,
            flightNumber: segment.flightNumber,
            // operating: 'VN',
            operating: ['BL', 'OV', ''].includes(segment.operating) ? 'VN' : segment.operating,
            fareBasisCode: '', // item.passenger.fareBasisCode || '', // FIXME - check
            bookingClass: segment.bookingClass || '',
            groupClass: segment.groupClass || '',
            fareValue: '',
            itineraryId: '',
            segmentId: segment.segmentId,
          })
        })

        return {
          airline: item.segments[0].airline,
          id: item.id,
          segmentIndicator: item.segmentIndicator,
          vendor: item.vendor,
          serviceType: item.serviceType,
          serviceSubCode: item.serviceSubCode,
          quantity: item.numberOfItems,
          fee: {
            base: item.basePrice,
            taxes: item.taxes,
          },
          payer: {
            firstName: payer.firstName,
            lastName: payer.lastName,
            title: resolveTitle(payer.firstName.split(' ')[payer.firstName.split(' ').length - 1]),
            paxType: payer.paxType,
            birthday: payer?.birthday || null,
            paxId: payer.paxId,
            parentPaxId: payer.parentPaxId || '',
            memberFly: [],
            PaxDocuments: payer.document,
            eTicketNumber: item.eticket.number,
            pricingInfo: payer.pricingInfo,
          },
          itineraries,
        }
      })

      // show modal confirm to modify
      const titleMsg = () => this.$createElement('div', {
        domProps: { innerHTML: this.$t('reservation.payments.confirmPaymentAncillary') },
      })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('reservation.paymentAncillary'),
          cancelTitle: this.$t('reservation.back'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')

            ancillaryPayments(getBookingData.value, ssrData)
              .then(() => {
                this.$bvModal.hide('modal-modify-payment-ancillary-services')
              })
              .finally(() => this.$bvModal.hide('modal-api-loading'))
          }
        })
    }

    function setArray(selectedArr, item) {
      const index = selectedArr ? selectedArr.findIndex(element => String(element.id) === String(item.id)) : -1
      // eslint-disable-next-line no-unused-expressions
      index >= 0 ? selectedArr.splice(index, 1) : selectedArr.push(item)
      return selectedArr
    }

    function chooseItem(item) {
      selectedItems.value = setArray(cloneDeep(selectedItems.value), item)
    }

    function isChecked(item) {
      return selectedItems.value.some(s => String(s.id) === String(item.id))
    }

    function onRowClicked(item, index, event) {
      if (item.eticket) {
        isChecked(item)
        chooseItem(item)
      }
    }

    function handlePaxsSelectAll(value) {
      if (value) {
        selectedItems.value = ancillaryServicesList.value
      } else {
        selectedItems.value = []
      }
    }

    watch(() => selectedItems.value, val => {
      if (val.length === ancillaryServicesList.value.length) {
        allPaxsSelected.value = true
      } else {
        allPaxsSelected.value = false
      }
    }, { immediate: true })

    const ancillaryServicesColumns = [
      { label: '', key: 'checkbox' },
      { label: 'fullName', key: 'full_name' },
      { label: 'itinerary', key: 'itinerary' },
      { label: 'services', key: 'services' },
      { label: 'amount', key: 'amount' },
      { label: 'total', key: 'total' },
    ]

    // function getRowClass(item, type) {
    //   if (type === 'row') {
    //     console.log(item, type)
    //     return 'cursor-pointer' // Trả về lớp CSS để áp dụng khi hover vào các hàng.
    //   }
    //   return null // Trả về null để không thay đổi lớp CSS cho các phần tử khác trong hàng.
    // }

    function handleHideModal() {
      ancillaryServicesList.value = []
    }

    return {
      getBookingData,
      ancillaryServicesColumns,
      ancillaryServicesList,
      allPaxsSelected,
      selectedItems,
      totalPriceSelected,
      resolveSsrNameByCode,

      isEmpty,
      formatCurrency,
      convertISODateTime,
      handleConfirmPaymentAncillary,

      handleShowModal,
      handleHideModal,

      handlePaxsSelectAll,
      isChecked,
      chooseItem,
      convertShortTrip,
      onRowClicked,
    //   getRowClass,
    }
  },
}
</script>

<style lang="scss">
::v-deep #table-ancillary-services.b-table.table-hover tbody tr:hover {
  cursor: pointer !important;
}
</style>
